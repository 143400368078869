// Page Module - Holds main page data
// Initial state
const state = () => ({
    nav: {
        open: false
    }
})

// getters
const getters = {
    navOpen: (state) => {
        return state.nav.open;
    }
}

// actions
const actions = {
    async setNavOpen({ commit }, open) {
        commit('setNav', open);
    }
}

// mutations
const mutations = {
    setNav(state, navOpen) {
        // Set data
        state.nav.open = navOpen;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}