// Page Module - Holds main page data
import { fetchData } from '../../bs.js'
// Initial state
const state = () => ({
    form: {}
})

// getters
const getters = {
    formDetails: (state) => {
        if (state.form === undefined) return undefined;
        console.log(state.form)
        return state.form;
    }
}

// actions
const actions = {
    async getForm({ commit }, formId) {

        // To Do: 
        // The router will force the page component to load again.
        // This will call this function again to get the page data.
        // We should store data already loaded so we don't call back every time the same
        // page is requested

        const formDetails = await fetchData('/formdetails', formId)
        .then(function (response) {
            return response;
        });

        commit('setForm', formDetails);
    },
    async submitForm({ commit }, form) {

        // To Do: 
        // Should this function be here? We're not using commit so maybe not
        console.log(commit)

        const sendResponse = await fetchData('/formsubmit', form)
            .then(function (response) {
                return response;
            });

        return sendResponse;
    },
    async fieldValid({ commit }, payload) {
        console.log(payload)
        commit('setFieldValid', payload);
    }
}

// mutations
const mutations = {
    setForm(state, formDetails) {
        // Set data
        state.form = formDetails;
    },
    setFieldValid(state,payload) {
        const fld = state.form.fields.find((fld) => fld.id === payload.id);
        if (fld) fld.valid = payload.valid;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}