<template>
    <button type="button" class="mainnav" v-on:click="open" :class="{ open: navOpen }"><span>Menu</span></button>
</template>

<script lang="js">
    import { mapGetters } from 'vuex'

    export default {
        name: "nav-button",
        computed: {
            ...mapGetters({ navOpen: 'nav/navOpen' })
        },
        methods: {
            open: function () {
                // react to route changes...
                this.$store.dispatch('nav/setNavOpen', !this.navOpen);
            }
        }
    };
</script>

<style>
    button.mainnav {
        font-size: 1em;
        background-color: transparent;
        border: 0;
        color: #fff;
        text-indent: -99px;
        overflow: hidden;
        opacity: 1;
        height: 20px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        -ms-flex: 0 0 1.75em;
        -webkit-flex: 0 0 1.75em;
        flex: 0 0 1.75em;
        -moz-transition: opacity linear .2s;
        -o-transition: opacity linear .2s;
        -webkit-transition: opacity linear .2s;
        transition: opacity linear .2s;
    }

        button.mainnav span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #fff;
            font-size: 0;
            -moz-transition: background-color .2s ease-out .6s;
            -o-transition: background-color .2s ease-out .6s;
            -webkit-transition: background-color .2s ease-out .6s;
            transition: background-color .2s ease-out .6s;
        }

            button.mainnav span:before {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: #fff;
                position: relative;
                top: -7px;
                -moz-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -o-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -webkit-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
            }

            button.mainnav span:after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: #fff;
                position: relative;
                top: 5px;
                -moz-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -o-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -webkit-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
            }

    /*Open*/
        button.mainnav.open span {
            background-color: var(--black);
            -moz-transition: background-color .2s ease-out;
            -o-transition: background-color .2s ease-out;
            -webkit-transition: background-color .2s ease-out;
            transition: background-color .2s ease-out;
        }

            button.mainnav.open span:before {
                transform: rotate(45deg);
                top: 0;
                -moz-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -o-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -webkit-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
            }

            button.mainnav.open span:after {
                transform: rotate(-45deg);
                top: -2px;
                -moz-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -o-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -webkit-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
            }

</style>