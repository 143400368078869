import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import store from './data/store.js';
import VueGtag from 'vue-gtag';

// Vue router
const routes = [
    //{ path: '/', name: 'HomePage', component: () => import('./components/page/HomePage.vue') },
    { path: '/404.html', name: '404', component: () => import('./components/page/404Page.vue') }, // 404 requires a 404 page in WebCharge and a 404.html page on the server that returns a 404 status from iis
    { path: '/:path(.*)*', name: 'SitePage', component: () => import('./components/page/SitePage.vue') } // Site page is the parent component that will load the child component for the page
];

// TO DO: set-up 404 responses for missing routes.
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
});

createApp(App).use(router).use(store).use(VueGtag, {
    config: {
        id: "G-K03R1PLC72",
    },
}).mount('#app');
