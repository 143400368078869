import { createStore } from 'vuex'
import page from './modules/page.js'
import nav from './modules/nav.js'
import snippet from './modules/snippet.js'
import form from './modules/form.js'

// Vuex data store
// Expose a new store instance.
export default createStore({
    modules: {
        page,
        nav,
        snippet,
        form
    }
});
