// Case Study Module - Holds case study data
import { fetchData } from '../../bs.js'
import snippets from '../default-data/snippets.json'

// Initial state
const state = () => ({
    tagSnippets: snippets
})

// getters
const getters = {
    taggedSnippets: (state) => {
        return state.tagSnippets;
    }
}

// actions
const actions = {
    async getTaggedSnippets({ commit }, params) {
        const sData = await fetchData('/taggedsnippet', { tagid: params.tagid, items: params.items })
        .then(function (response) {
            return response;
        });
        commit('setSnippets', sData);
    }
}

// mutations
const mutations = {
    setSnippets(state, sData) {
        // Set data
        state.tagSnippets = sData;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}