// Page Module - Holds main page data
import { fetchData } from '../../bs.js'
import { baseContent } from '../default-data/destinationDefault.js'
// Initial state
const state = () => ({
    page: {},
    appLoaded: false
})

// getters
const getters = {
    pageComponent: (state) => {
        if (state.page.contents === undefined) return undefined;
        return state.page.contents.PageComponent;
    },
    pageMeta: (state) => {
        const meta = {
            title: state.page.meta_Title,
            description: state.page.meta_Description,
            socialDescription: state.page.social_Description,
            socialImage: state.page.social_Image,
            socialImage_Alt: state.page.social_Image_Alt,
            socialTitle: state.page.social_Title
        }
        return meta;
    },
    pageContent: (state) => {
        return state.page.contents;
    },
    pageBanner: (state) => {
        if (state.page.files === undefined) return undefined;
        return state.page.files.filter(file => file.type === "i");
    },
    mainLinks: (state) => {
        if (state.page.links === undefined) return undefined;
        return state.page.links.pages;
    }
}

// actions
const actions = {
    async getPage({ commit }, page) {
        // Default content for search engines
        if (baseContent[page]) commit('setPage', baseContent[page]); // Only need to run this on entry to the site

        // To Do:
        // The router will force the page component to load again.
        // This will call this function again to get the page data.
        // We should store data already loaded so we don't call back every time the same
        // page is requested

        const pageData = await fetchData('/pagecontent', page)
            .then(function (response) {
                return response;
            });

        commit('setPage', pageData);
    }
}

// mutations
const mutations = {
    setPage(state, pageData) {
        // Set data
        state.page = pageData;
        state.appLoaded = true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}