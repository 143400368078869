<template>
    <header>
        <div class="container flex">
            <router-link to="/" class="logo">
                <picture>
                    <source type="image/svg+xml" srcset="/_resources/files/template/orantec-reverse.svg" />
                    <img srcset="/_resources/files/template/orantec-reverse.png 1x,/_resources/files/template/orantec-reverse2x.png 2x" src="/_resources/files/template/orantec-reverse.png" alt="Orantec" />
                </picture>
            </router-link>
            <slot></slot>
        </div>
    </header>
</template>

<script lang="js">
    export default {
        name: "site-header"
    };
</script>

<style>
    header {
        background-color: var(--black);
        z-index: 3;
        position: sticky;
        top: 0;
    }

        header div.flex {
            height: var(--header-height);
            justify-content: space-between;
            align-items: center;
        }

        header div.container > a.logo {
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 10em;
        }

            header div.container > a.logo picture img {
                max-width: 10em;
                display: block;
                margin-bottom: 0.25em;
            }


        header div.container > button.nav {
            opacity: 0;
            -moz-transition: opacity linear .2s;
            -o-transition: opacity linear .2s;
            -webkit-transition: opacity linear .2s;
            transition: opacity linear .2s;
        }

    @media screen and (min-height: 800px) {
        header div.flex {
            height: var(--header-height-lge);
        }
    }
</style>