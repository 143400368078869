// Initial content for main destinations to populate content before callback
import home from './home.json'
import work from './work.json'
import about from './about.json'
import contact from './contact.json'

export const baseContent = {
    "/": home,
    "/work": work,
    "/about": about,
    "/contact": contact
}
