<template>
    <li>
        <router-link :to="model.pageLink">{{ model.name }}</router-link>
        <ul v-if="model.pages.length > 0">
            <nav-item :model="page" v-for="page in model.pages" :key="page.number"></nav-item>
        </ul>
    </li>
</template>

<script lang="js">
    export default {
        name: "nav-item",
        props: {
            model: Object
        }
    };
</script>

<style>
    div.app > nav.mainnav ul li:not(:last-child) {
        margin-bottom: 1.5em;
    }

    div.app > nav.mainnav ul li:first-child {
        margin-top: 3em;
    }

    div.app > nav.mainnav ul li:last-child {
        margin-bottom: 3em;
    }

    div.app > nav.mainnav ul li a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-family: var(--font-heading);
        font-size: var(--font-links);
        font-weight: 600;
        letter-spacing: 0.25em;
        -moz-transition: color .2s linear;
        -o-transition: color .2s linear;
        -webkit-transition: color .2s linear;
        transition: color .2s linear;
    }

        div.app > nav.mainnav ul li a:hover {
            color: var(--orange);
        }
</style>