<template>
    <nav role="navigation" class="mainnav" v-if="mainLinks && mainLinks.length > 0" :class="{ open: navOpen }">
        <ul>
            <nav-item :model="page" v-for="page in mainLinks" :key="page.number"></nav-item>
        </ul>
    </nav>
</template>

<script lang="js">
    import { mapGetters } from 'vuex'

    import NavItem from './NavItem.vue'
    export default {
        name: "nav-menu",
        components: { NavItem },
        computed: {
            ...mapGetters({ mainLinks: 'page/mainLinks', navOpen: 'nav/navOpen' })
        }
    };
</script>

<style>
    nav.mainnav {
        position: sticky;
        top: var(--header-height);
        z-index: 3;
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        background: var(--black-gradent);
        opacity: 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-transition: opacity .2s linear .4s;
        -o-transition: opacity .2s linear .4s;
        -webkit-transition: opacity .2s linear .4s;
        transition: opacity .2s linear .4s;
    }

        nav.mainnav ul {
            max-height: 0;
            overflow: hidden;
            margin: 1px 0;
            padding: 0 3em;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: var(--black);
            list-style-type: none;
            -moz-transition: max-height .4s ease-in-out;
            -o-transition: max-height .4s ease-in-out;
            -webkit-transition: max-height .4s ease-in-out;
            transition: max-height .4s ease-in-out;
        }

    /*Open*/
    nav.mainnav.open {
        opacity: 1;
        -moz-transition: opacity .2s linear;
        -o-transition: opacity .2s linear;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
    }

        nav.mainnav.open ul {
            max-height: 16em;
            -moz-transition: max-height .4s ease-in-out .2s;
            -o-transition: max-height .4s ease-in-out .2s;
            -webkit-transition: max-height .4s ease-in-out .2s;
            transition: max-height .4s ease-in-out .2s;
        }

    @media screen and (min-width: 600px) {

        nav.mainnav {
            height: calc(100vh - 110px);
            opacity: 1;
            background: var(--orange-gradient);
            max-height: none;
            border-top: none;
            border-bottom: none;
            overflow: hidden;
            -ms-flex: 0 0 0;
            -webkit-flex: 0 0 0;
            flex: 0 0 0;
            -moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }

            nav.mainnav ul {
                margin-bottom: 0;
                height: 100%;
                max-height: 100%;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }

        nav.mainnav.open {
            max-height: none;
            -ms-flex: 0 0 30%;
            -webkit-flex: 0 0 30%;
            flex: 0 0 30%;
            -moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }

            nav.mainnav.open ul {
                max-height: 100%;
            }
    }

    @media screen and (min-height: 800px) {
        nav.mainnav {
            top: var(--header-height-lge);
        }
    }

</style>