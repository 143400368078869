<template>
    <footer>
        <img src="/_resources/files/template/footer.jpg" alt="Code and a globe - bottom half" />
        <div class="container">
            <h1>Got a Project?</h1><h2>We'd love to hear about it!</h2>
            <p><router-link to="/contact">Get in touch</router-link></p>
            <p class="copyright">&copy; {{ year }} Orantec</p>
        </div>
    </footer>
</template>

<script lang="js">
    export default {
        name: "site-footer",
        data() {
            return {
                year: new Date().getFullYear()
            };
        }
    };
</script>

<style>
    footer {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1;
        color: #fff;
    }

        footer > img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: top right;
            z-index: 1;
        }

        footer .container {
            position: relative;
            z-index: 2;
            padding-top: 3.25em;
            padding-bottom: 1em;
        }

        footer h1 {
            font-weight: 200;
            margin-bottom: 0;
        }

        footer h2 {
            font-size: var(--font-h3);
            font-weight: 200;
        }

    footer .copyright {
        font-size: 0.65rem;
        margin-top: 3rem;
    }

        footer a {
            color: var(--orange);
            text-decoration: none;
        }

</style>