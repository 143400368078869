<template>
    <site-header><nav-button></nav-button></site-header>
    <div class="site" :class="{ navopen: navOpen }">
        <main role="main"><router-view></router-view></main>
        <site-footer></site-footer>
    </div>
    <nav-menu></nav-menu>
</template>

<script>
    import SiteHeader from './components/ui/SiteHeader.vue'
    import NavButton from './components/ui/NavButton.vue'
    import SiteFooter from './components/ui/SiteFooter.vue'
    import NavMenu from './components/ui/NavMenu.vue'
    import { mapGetters } from 'vuex'

    export default {
        name: 'App',
        mounted() {
            // Intercept links and use vue router on internal links
            window.addEventListener('click', event => {
                // ensure we use the link, in case the click has been received by a subelement
                let { target } = event
                while (target && target.tagName !== 'A') target = target.parentNode
                // handle only links that do not reference external resources
                if (target && target.matches("a:not([href*='://'])") && target.href) {
                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) return
                    // don't handle when preventDefault called
                    if (defaultPrevented) return
                    // don't handle right clicks
                    if (button !== undefined && button !== 0) return
                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute('target')
                        if (/\b_blank\b/i.test(linkTarget)) return
                    }
                    // don't handle same page links/anchors
                    const url = new URL(target.href)
                    const to = url.pathname
                    if (window.location.pathname !== to && event.preventDefault) {
                        event.preventDefault()
                        this.$router.push(to)
                    }
                }
            })
        },
        computed: {
            ...mapGetters({ navOpen: 'nav/navOpen' })
        },
        components: {
            SiteHeader,
            NavButton,
            SiteFooter,
            NavMenu
      }
    }
</script>

<style>
/*#region Variables 
--------------------------  */

    :root {
        /*Colours*/
        --black: #1d1d1b;
        --light-orange: #f1b65d;
        --orange: #f39200;
        --dark-orange: #7d4b00;
        --light-black: #d7d7cd;
        --medium-light-black: #7c7c74;
        --medium-black: #5c5c56;
        --black-60: rgba(29,29,27,0.6);
        --orange-60: #f3920061;
        --orange-80: #f39200cb;
        --orange-gradient: linear-gradient(90deg, rgba(29,29,27,1) 0%, rgba(243,146,0,1) 92%, rgba(29,29,27,1) 100%);
        --black-gradent: linear-gradient(90deg, rgba(29,29,27,1) 8%, rgba(243,146,0,1) 50%, rgba(29,29,27,1) 91%);
        --red: #e43500;
        /*SVG Filters*/
        --svg-orange: url(#orange);
        --svg-red: url(#red);
        --svg-light-black: url(#lightblack);
        --svg-medium-light-black: url(#mediumlightblack);
        --svg-medium-black: url(#mediumblack);
        /*Fonts */
        --font-heading: poppins, sans-serif;
        --font-text: poppins, sans-serif;
        /* Fluid Font Sizes - Calculate the sizes based on the viewport width. 
        This will change the fonts based on the viewport size. The min and max set the font at those sizes. 
        They do not stop the font size changing above and below those sizes so we still need to put those in the media queries
        */
        --viewport-min: 320px;
        --viewport-max: 1600px;
        --viewport-min-v: 320;
        --viewport-max-v: 1600;
        --font-h1-min-v: 30;
        --font-h1-max-v: 38;
        --font-h1-min: 30px;
        --font-h1-max: 48px;
        --font-h1: calc(var(--font-h1-min) + (var(--font-h1-max-v) - var(--font-h1-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-h2-min-v: 28;
        --font-h2-max-v: 36;
        --font-h2-min: 28px;
        --font-h2-max: 40px;
        --font-h2: calc(var(--font-h2-min) + (var(--font-h2-max-v) - var(--font-h2-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-h3-min-v: 22;
        --font-h3-max-v: 28;
        --font-h3-min: 22px;
        --font-h3-max: 28px;
        --font-h3: calc(var(--font-h3-min) + (var(--font-h3-max-v) - var(--font-h3-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-p-min-v: 16;
        --font-p-max-v: 20;
        --font-p-min: 16px;
        --font-p-max: 20px;
        --font-p: calc(var(--font-p-min) + (var(--font-p-max-v) - var(--font-p-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-p-small-min-v: 11;
        --font-p-small-max-v: 14;
        --font-p-small-min: 11px;
        --font-p-small-max: 14px;
        --font-p-small: calc(var(--font-p-small-min) + (var(--font-p-small-max-v) - var(--font-p-small-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-links-min-v: 18;
        --font-links-max-v: 24;
        --font-links-min: 18px;
        --font-links-max: 24px;
        --font-links: calc(var(--font-p-min) + (var(--font-p-max-v) - var(--font-p-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        /*Header height */
        --header-height: 3.5em;
        --header-height-lge: 6.25em;
    }

/*#endregion */

/*#region Classes 
--------------------------  */

    /* Flex */
    .flex {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

        .flex.column {
            -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
        }

        .flex > * {
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
        }

    .container {
        margin: 0 auto;
        max-width: 100em;
        padding: 0 1rem;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    @media screen and (min-width: 450px) {
        .container {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    @media screen and (min-width: 800px) {
        .container {
            padding: 0 3.25em;
        }
    }

    @media screen and (min-width: 1200px) {
        .container {
            padding: 0 6.25em;
        }
    }

/*#endregion */

/*#region Typography 
--------------------------  */

    h1, h2, h3, h4, h5 {
        font-family: var(--font-heading);
        font-weight: 400;
        line-height: 1.2em;
        margin: 0 0 1rem 0;
    }

    h1 {
        font-size: var(--font-h1);
    }

    h2 {
        font-size: var(--font-h2);
    }

    h3, h4, h5 {
        font-size: var(--font-h3);
    }

    p {
        font-size: var(--font-p);
        line-height: 1.5em;
        margin: 0 0 1em 0;
    }

    strong {
        font-weight: 400;
    }

    body {
        font-family: var(--font-text);
        font-weight: 200;
    }

    img {
        max-width: 100%;
    }

/*#endregion */

/*#region  Main Structure
-------------------------------------  */

    html {
        font-size: 1em;
        scroll-behavior: smooth;
        min-width: 20em;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
        display: block;
    }

    picture {
        display: inline;
    }

    body {
        position: relative;
        text-align: left;
        font-size: 1em;
        background-color: var(--black);
        color: #fff;
        width: 100%;
        padding: 0.5em;
        margin: 0;
        min-width: 20em;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    main {
        background-color: #fff;
        color: var(--black);
        position: relative;
        z-index: 2;
    }

        main .container {
            padding-top: 1em;
            padding-bottom: 1em;
        }

        main *:last-child {
            margin-bottom: 0;
        }

        main a {
            color: var(--black);
            -moz-transition: color .2s linear;
            -o-transition: color .2s linear;
            -webkit-transition: color .2s linear;
            transition: color .2s linear;
        }

            main a:hover {
                color: var(--orange);
            }

        main .text {
            max-width: 45em;
        }

    div.app {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

        div.app header {
            flex: 0 0 100%;
        }

        div.app > div.site {
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
            order: 2;
            -moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }

        div.app > nav.mainnav {
            order: 1;
        }

    @media screen and (min-width: 600px) {
        div.app > div.site {
            order: 1;
        }

        div.app > nav.mainnav {
            order: 2;
        }

        div.app > div.site.navopen {
            -ms-flex: 0 0 70%;
            -webkit-flex: 0 0 70%;
            flex: 0 0 70%;
        }
    }

    @media screen and (min-height: 600px) {
        main .container {
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }

    @media screen and (min-height: 800px) {
        main .container {
            padding-top: 3.25em;
            padding-bottom: 3.25em;
        }
    }

/*#endregion */

/*#region  Loading animation
-------------------------------------  */

    .loader {
        display: block;
        font-size: 10px;
        margin: 0 auto;
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;
        background: var(--medium-black);
        background: -moz-linear-gradient(left, var(--medium-black) 10%, var(--orange-80) 42%);
        background: -webkit-linear-gradient(left, var(--medium-black) 10%, var(--orange-80) 42%);
        background: -o-linear-gradient(left, var(--medium-black) 10%, var(--orange-80) 42%);
        background: -ms-linear-gradient(left, var(--medium-black) 10%, var(--orange-80) 42%);
        background: linear-gradient(to right, var(--medium-black) 10%, var(--orange-80) 42%);
        -webkit-animation: load3 1.2s infinite linear;
        animation: load3 1.2s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

        .loader:before {
            width: 50%;
            height: 50%;
            background: var(--medium-black);
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }

        .loader:after {
            background: var(--orange-80);
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

    @-webkit-keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

/*#endregion */

</style>
